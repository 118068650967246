import { useEffect, useRef, useState } from 'react'
import { DrawerProps } from './types'
import { CSSTransition } from 'react-transition-group'
import useWindowSize from 'hooks/useWindowSize'
import styles from './Drawer.module.scss'
import Icon from 'components/icon/Icon'
import classNames from 'classnames'
import { CldImage } from 'next-cloudinary'
import { Heading } from '@boltenergy-be/design-system'
import { createPortal } from 'react-dom'

const DrawerContent = ({ children, extraHeaderElement, isOpen, onRequestClose, contentClassName, headerImage, title }: DrawerProps) => {
  // Refs
  const drawerRef = useRef<HTMLDivElement>(null)

  // Window size
  const { isTablet } = useWindowSize()

  /**
   * Hides modal on click outside and on ESC
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (drawerRef.current && !drawerRef.current.contains(e.target as HTMLDivElement)) {
        onRequestClose()
      }
    }
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [onRequestClose])

  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <>
      <CSSTransition nodeRef={drawerRef} in={isOpen} timeout={300} classNames={`slide-${isTablet ? 'up' : 'left'}`} unmountOnExit>
        <aside className={styles.drawer} ref={drawerRef}>
          <div
            className={classNames(styles['drawer-header'], {
              [styles['with-extra']]: !!extraHeaderElement,
              [styles['with-image']]: !!headerImage
            })}
          >
            {isTablet ? (
              <button className={styles['drawer-close']} onClick={onRequestClose}>
                <Icon name="x" size={14} />
              </button>
            ) : (
              <Icon name="arrowCollapse" size={20} className={styles['drawer-close']} onClick={onRequestClose} />
            )}

            {extraHeaderElement}

            {headerImage && <CldImage className={styles['header-img']} src={headerImage.url} alt={headerImage.alt} fill />}
          </div>

          <div className={classNames(styles['drawer-content'], contentClassName)} suppressHydrationWarning>
            {title && (
              <Heading as="h1" variant="h3" className="mb-500">
                {title}
              </Heading>
            )}
            {children}
          </div>
        </aside>
      </CSSTransition>
      {isOpen && <div className="overlay" />}
    </>
  )
}

const Drawer = (props: DrawerProps) => {
  const [root, setRoot] = useState<HTMLElement>(null)

  useEffect(() => {
    const drawerPortal = document?.getElementById('drawer-portal')
    setRoot(drawerPortal || document?.body)
  }, [])

  return root ? createPortal(<DrawerContent {...props} />, root) : null
}

export default Drawer
