import { BannerTypes } from 'components/banner/types'
import { Product } from 'types/product-data'
import { SourceType } from 'types/marketing-params'
import { FuelPricelistUrls } from 'types/customer'

export enum SignChannel {
  CONFIRMATION_MAIL = 'Confirmation Mail',
  SIGNATURE = 'Signature'
}

export enum OptinCta {
  CONTACT_ME = 'Contact Me',
  SEND_DETAILS = 'Send Details',
  SWITCH_NOW = 'Switch Now'
}

export enum SimulationType {
  BOLT_GO = 'Bolt Go',
  EAV = 'EAV',
  EXPRESS = 'Express',
  FIXED_AMOUNT = 'Fixed Amount',
  PRICING_SIMULATION = 'Pricing Simulation'
}

export enum MarketingDataKeys {
  ADJUSTABLE_PRODUCT = 'adjustableProduct',
  AVAILABLE_PRODUCTS = 'availableProducts',
  AGENT_ID = 'agentID',
  BANNER = 'banner',
  BANNER_TYPE = 'bannerType',
  CALL_CAMPAIGN = 'callCampaign',
  CALL_PARTNER_ACCOUNT = 'callPartnerAccount',
  CAMPAIGN_NAME = 'campaignName',
  CONFIRMATION_FROM_PORTAL_REGISTRATION = 'confirmationFromPortalRegistration',
  EAN_CODE_MANDATORY = 'eanCodeMandatory',
  EMAIL_CONFIRMATION_FLOW = 'emailConfirmationFlow',
  EVENT_ID = 'eventID',
  EVENT_NAME = 'eventName',
  EXECUTE_CREDIT_CHECK = 'executeCreditCheck',
  GCLID = 'gclid',
  NO_EAN_FLOW = 'noEanFlow',
  NO_OPTIN_ONE = 'noOptinOne',
  OPTIN_CTA = 'optinCta',
  PARTNER_ID = 'partnerID',
  PREVIOUS_SUPPLIER = 'previousSupplier',
  PRICELIST_URLS = 'pricelistUrls',
  RESET_BUTTON = 'resetButton',
  SALESFORCE_USER_ALIAS = 'sfUserAlias',
  SHIFT_ID = 'shiftID',
  SIGN_CHANNEL = 'signChannel',
  SIMULATION_SO = 'simulationSo',
  SIMULATION_TYPE = 'simulationType',
  SOURCE_TYPE = 'sourceType',
  TRIGGER_EVENTS = 'triggerEvents'
}

export type MarketingStore = {
  [MarketingDataKeys.ADJUSTABLE_PRODUCT]: boolean
  [MarketingDataKeys.AVAILABLE_PRODUCTS]?: Product[]
  [MarketingDataKeys.AGENT_ID]?: string
  [MarketingDataKeys.BANNER]?: string
  [MarketingDataKeys.BANNER_TYPE]?: BannerTypes
  [MarketingDataKeys.CALL_CAMPAIGN]?: string
  [MarketingDataKeys.CALL_PARTNER_ACCOUNT]?: string
  [MarketingDataKeys.CAMPAIGN_NAME]?: string
  [MarketingDataKeys.CONFIRMATION_FROM_PORTAL_REGISTRATION]: boolean
  [MarketingDataKeys.EAN_CODE_MANDATORY]: boolean
  [MarketingDataKeys.EMAIL_CONFIRMATION_FLOW]: boolean
  [MarketingDataKeys.EVENT_ID]?: string
  [MarketingDataKeys.EVENT_NAME]?: string
  [MarketingDataKeys.EXECUTE_CREDIT_CHECK]: boolean
  [MarketingDataKeys.GCLID]: string
  [MarketingDataKeys.NO_EAN_FLOW]: boolean
  [MarketingDataKeys.NO_OPTIN_ONE]: boolean
  [MarketingDataKeys.OPTIN_CTA]: OptinCta
  [MarketingDataKeys.PARTNER_ID]?: string
  [MarketingDataKeys.PREVIOUS_SUPPLIER]: boolean
  [MarketingDataKeys.PRICELIST_URLS]?: FuelPricelistUrls
  [MarketingDataKeys.RESET_BUTTON]: boolean
  [MarketingDataKeys.SALESFORCE_USER_ALIAS]?: string
  [MarketingDataKeys.SHIFT_ID]?: string
  [MarketingDataKeys.SIGN_CHANNEL]?: SignChannel[]
  [MarketingDataKeys.SIMULATION_SO]?: string
  [MarketingDataKeys.SIMULATION_TYPE]: SimulationType[]
  [MarketingDataKeys.SOURCE_TYPE]: SourceType
  [MarketingDataKeys.TRIGGER_EVENTS]: string
}

// PAYLOADS

export type UpdateMarketingDataPayload = {
  key: MarketingDataKeys
  value: BannerTypes | boolean | Product | SignChannel | string | unknown[] | FuelPricelistUrls
}

export type UpdateMarketingStorePayload = {
  marketingStore: MarketingStore
}
