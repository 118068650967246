/* eslint-disable sort-keys */
import { AB_EXPERIMENTS, AllExperiments, TRUSTPILOT_BANNER_VARIANTS } from 'types/tracking'

export const ALL_EXPERIMENTS: AllExperiments = {
  [AB_EXPERIMENTS.TRUSTPILOT_BANNER]: {
    variants: [TRUSTPILOT_BANNER_VARIANTS.CONTROL, TRUSTPILOT_BANNER_VARIANTS.TRUSTPILOT],
    active: false
  }
}

export const AB_TESTING_EVENTS = {
  START: '$experiment_started'
} as const

export const HOME_TRACKING = {
  CLICK_EXPRESS: 'click-express',
  CLICK_SIM_REGISTRATION: 'click-simulate-registration',
  CLICK_SIM_SIMULATION: 'click-simulate',
  CLICK_SIM_SIMULATION_HEADER: 'click-simulate-header'
} as const

export const DRAWER_TRACKING = {
  // Common open drawer event (define drawer name in params)
  OPEN: 'open-drawer',

  // Subscribe drawer
  OPEN_SUBSCRIBE_DRAWER: 'start-subscribe',
  SKIP_SUBSCRIBE: 'skip-subscribe',
  FINISH_SUBSCRIBE: 'finish-subscribe',
  SUCCESSFUL_LEAD_GEN: 'successful-lead-gen',
  // Producer drawer
  OPENED_PRODUCER_DRAWER: 'opened-producer-drawer',
  CHANGED_PRODUCER: 'changed-producer',
  // Price drawer
  OPENED_PRICE_DETAILS: 'open-price-details',
  // Forced amount drawer
  OPENED_FORCED_AMOUNT_DRAWER: 'opened-forced-amount-drawer',
  ADDED_FORCED_AMOUNT: 'added-forced-amount',
  REMOVED_FORCED_AMOUNT: 'removed-forced-amount',
  // Usage drawer
  OPENED_USAGE_DRAWER: 'opened-usage-drawer',
  CHANGED_USAGE: 'changed-usage'
}

export const SIMULATION_TRACKING = {
  CLICK_BOLT_GO: 'click-bolt-go',
  CLOSE: 'close-simulation',
  FINISH: 'finish-simulation',
  SELECT_PROPOSITION: 'select-proposition',
  START: 'start-simulation',
  STEP_OPENED: 'step-opened',
  TOGGLE_USAGE: 'toggle-usage',
  FAILED: 'simulation-failed',
  CLICK_SIMULATION_CTA: 'click-simulation-cta'
} as const

export const REGISTRATION_TRACKING = {
  FINISH: 'finish-registration',
  FINISH_CONFIRMATION: 'finish-registration-confirmation',
  REDIRECTED_AWAY: 'redirected-away',
  START: 'start-registration',
  STEP_OPENED: 'step-opened'
} as const

export const BOLT_GO_TRACKING = {
  OPENED: 'opened-boltGO-landing-page'
} as const

export const ENERGY_GARDEN_TRACKING = {
  STEP_OPENED: 'energy-garden-step-opened',
  PRODUCTS_SELECTED: 'products-selected',
  PAYMENT_INITIATED: 'payment-initiated',
  HOME_CTA_CLICKED: 'home-cta-clicked',
  START_FLOW_CLICKED: 'start-flow-clicked'
} as const

export const DYNAMIC_PRICING_QUIZ_TRACKING = {
  STARTED: 'started-dynamic-pricing-quiz',
  ABANDONED: 'abandoned-dynamic-pricing-quiz',
  RESULT_INTERESTING: 'dynamic-pricing-quiz-result-interesting',
  RESULT_NOT_INTERESTING: 'dynamic-pricing-quiz-result-not-interesting',
  RESULT_WORTH_CONSIDERING: 'dynamic-pricing-quiz-result-worth-considering',
  RESULT_NOT_APPLICABLE: 'dynamic-pricing-quiz-result-not-applicable',
  RESULT_NOT_FLANDERS: 'dynamic-pricing-quiz-result-not-flanders'
} as const
