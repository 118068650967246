import { ElectricityAndGasPrices, MeterType, MeterUsage, SimulationSteps } from 'features/simulation/types'
import { CountryCode } from 'libphonenumber-js/max'
import { SimulationType } from 'store/marketing/types'
import { AllProducts, ELProduct, NGProduct, CustomerType, Product } from 'types/product-data'
import { Language } from 'types/language'
import { RootState, store } from 'store'
import { CustomerFlows } from 'store/customer/enums'
import { OptinPoint } from 'types/marketing-params'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { normalizeText } from 'utils/format'
import { Producer } from 'types/producer'
import { getProductToSelect, getSimulatedPrice } from './price'
import { routes } from 'utils/routes'
import Router from 'next/router'
import { CreateProspectData } from 'types/customer'
import { goToStep, toggleContactMe } from 'store/customer/slice'

/**
 * Check if the environment is staging or preview
 * @returns {boolean}
 */
export const checkIsStaging = (): boolean => process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

/**
 * Convertes a file to base64
 *
 * @param {File} file
 * @returns {Promise<string|ArrayBuffer>}
 */
export const fileToBase64 = (file: File): Promise<string | ArrayBuffer> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
  })

/**
 * Returns the location.origin of the Bolt website
 * for each env.
 *
 * @returns string
 */
export const getBoltWebsiteOrigin = () => {
  let origin

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.NODE_ENV) {
    // Local
    case 'development':
      origin = 'http://localhost:9000'
      break

    // Staging
    case 'preview':
      origin = 'https://staging.boltenergie.be'
      break

    // Production
    default:
      origin = 'https://boltenergie.be'
  }

  return origin
}

/**
 * Returns the bolt go prices for API requests
 *
 * @param {ElectricityAndGasPrices} boltGoPrices
 * @param {SimulationType} simulationType
 * @param {boolean} needsGas
 * @returns {ElectricityAndGasPrices|undefined}
 */
export const getBoltGoPricesForApiRequests = (
  boltGoPrices: ElectricityAndGasPrices,
  simulationType: SimulationType,
  needsGas: boolean
): ElectricityAndGasPrices => {
  if (simulationType === SimulationType.BOLT_GO) {
    if (boltGoPrices) return { electricity: boltGoPrices.electricity, gas: needsGas ? boltGoPrices.gas : 0 }

    return {
      electricity: 0,
      gas: 0
    }
  }

  return undefined
}

/**
 * Returns the fixed prices for API requests
 *
 * @param {ElectricityAndGasPrices} fixedPrices
 * @param {SimulationType} simulationType
 * @returns {ElectricityAndGasPrices}
 */
export const getFixedPricesForApiRequests = (
  fixedPrices: ElectricityAndGasPrices,
  simulationType: SimulationType
): ElectricityAndGasPrices => {
  if (simulationType === SimulationType.FIXED_AMOUNT) {
    if (fixedPrices) return fixedPrices

    return {
      electricity: 0,
      gas: 0
    }
  }

  return undefined
}

/**
 * Returns the pricelist for a given electricity product
 *
 * @param {ELProduct} product
 * @param {AllProducts} allProducts
 * @param {boolean} isCompany
 * @param {Language} language
 * @returns {string}
 */
export const getPricelistFromElectricityProducts = (
  product: ELProduct,
  allProducts: AllProducts,
  isCompany: boolean,
  language: Language
): string => {
  const pricelistType = isCompany ? CustomerType.PROFESSIONAL : CustomerType.RESIDENTIAL
  return allProducts ? allProducts.electricity[product].pricelists[pricelistType][language] : ''
}

/**
 * Returns the pricelist for a given gas product
 *
 * @param {NGProduct} product
 * @param {AllProducts} allProducts
 * @param {boolean} isCompany
 * @param {Language} language
 * @returns {string}
 */
export const getPricelistFromGasProducts = (
  product: NGProduct,
  allProducts: AllProducts,
  isCompany: boolean,
  language: Language
): string => {
  const pricelistType = isCompany ? CustomerType.PROFESSIONAL : CustomerType.RESIDENTIAL
  return allProducts ? allProducts.gas[product].pricelists[pricelistType][language] : ''
}

/**
 * Returns the total volume based on the meter type
 *
 * @param {MeterType} meterType
 * @param {MeterUsage} meterUsage
 * @returns {number}
 */
export const getTotalElectricityVolumeForMeterType = (meterType: MeterType, meterUsage: MeterUsage): number => {
  const { double, exclusiveNight, single } = meterUsage?.electricity?.consumption

  if (meterType === MeterType.DOUBLE_RATE) {
    return Number(double?.day ?? 0) + Number(double?.night ?? 0) + Number(exclusiveNight ?? 0)
  }

  return Number(single ?? 0) + Number(exclusiveNight ?? 0)
}

/**
 * Returns the total volume based on the meter type
 *
 * @param {MeterType} meterType
 * @param {MeterUsage['electricity']['injection']} injection
 * @returns {number}
 */
export const getTotalInjectionVolumeForMeterType = (meterType: MeterType, injection: MeterUsage['electricity']['injection']): number => {
  const { double, single } = injection

  if (meterType === MeterType.DOUBLE_RATE) {
    return Number((double?.day ?? 0) + (double?.night ?? 0))
  }

  return Number(single ?? 0)
}

/**
 * Handles the contact me button click (for noOptinOne flow)
 * @param {Product} product
 * @param {() => void} onRequestClose
 */
export const handleContactMeClick = async (product: Product, onRequestClose?: () => void) => {
  const route = Router.route
  const isSimulationPage = route === '/simulation'

  store.dispatch(toggleContactMe(product))

  onRequestClose ? onRequestClose() : store.dispatch(goToStep({ flow: CustomerFlows.SIMULATION, step: SimulationSteps.PROPOSITION }))

  if (!isSimulationPage) return Router.push(routes(Router.locale).simulation)
}

/**
 * Scrolls to top of window
 */
export const handleScrollToTop = (): void => {
  window?.scrollTo({ behavior: 'smooth', top: 0 })
}

export const hasNoNumber = (text: string): boolean => {
  return !/\d/.test(text)
}

/**
 * Returns if the given barcode string is a valid EAN number
 *
 * @param {string} barcode
 * @returns {boolean}
 */
export const isValidEAN = (barcode: string): boolean => {
  // Check if EAN is 18 characters long
  if (barcode.length !== 18) return false

  // Check if the EAN starts with 54
  if (!barcode.startsWith('54')) return false

  // Check if EAN is a number
  if (isNaN(parseInt(barcode, 10))) return false

  // Check if the last digit is a number
  const lastDigit = parseInt(barcode.substring(barcode.length - 1), 10)
  if (isNaN(lastDigit)) return false

  // Reverse the all the numbers except the last
  const arr = barcode
    .substring(0, barcode.length - 1)
    .split('')
    .reverse()

  // Calculate the sum of all the numbers
  // Numbers on even positions are multiplied by 3 and then added
  // Numbers on odd positions are added without being multiplied
  let sum = 0
  for (let i = 0; i < arr.length; i++) {
    if (i % 2 === 0) {
      sum += parseInt(arr[i], 10) * 3
    } else {
      sum += parseInt(arr[i], 10)
    }
  }

  // Calculate the checksum
  let checkSum = sum % 10

  // Check & return if the checksum equals the last digit if the checksum equals 0
  if (checkSum === 0) return checkSum === lastDigit

  // Recalculate the checksum & check if last digit matches if checksum didn't equal 0
  checkSum = 10 - (sum % 10)

  return checkSum === lastDigit
}

/**
 * Returns if a given postal code is valid
 *
 * @param postalCode
 * @returns boolean
 */
export const isValidPostalCode = (postalCode: number): boolean => {
  return postalCode.toString().length === 4
}

type MapCreateProspectData = {
  language: Language
  producer: Producer
  startedFulfillmentFlow?: boolean
  phoneCountryCode?: CountryCode
  customProduct?: Product
}

/**
 * Maps the CreateProspectData & returns it
 *
 * @param {MapCreateProspectData} data
 * @return {CreateProspectData}
 */
export const mapCreateProspectData = ({
  language,
  producer,
  startedFulfillmentFlow,
  phoneCountryCode,
  customProduct
}: MapCreateProspectData): CreateProspectData => {
  const { app, marketing, customer } = store.getState() as RootState

  const price = getSimulatedPrice(customProduct)
  const product = customProduct || getProductToSelect()

  return {
    boltGoPrices: getBoltGoPricesForApiRequests(
      customer.boltGoPrices,
      customer.inputs.simulation.chosenSimulationType,
      customer.inputs.personalData.needsGas
    ),
    ...(customer.inputs.personalData.isCompany && {
      companyName: customer.inputs.personalData.companyName,
      enterpriseNumber: customer.inputs.personalData.companyNumber
    }),
    contactMoment: customer.inputs.personalData.contactMoment,
    counterType: customer.inputs.meterDetails.counterType,
    creditCheck: marketing.executeCreditCheck && !!customer.inputs.personalData.companyNumber,
    email: customer.inputs.personalData.email,
    energyUsageGrade: customer.inputs.simulation.usageGrade,
    firstName: customer.inputs.personalData.firstName,
    fixedPrices: getFixedPricesForApiRequests(customer.fixedPrices, customer.inputs.simulation.chosenSimulationType),
    forcedAmount: customer.inputs.simulation.forcedAmount,
    hasSolarPanels: customer.inputs.meterDetails.hasSolarPanels,
    isCompany: customer.inputs.personalData.isCompany || false,
    language,
    lastName: customer.inputs.personalData.lastName,
    marketing: {
      agentID: marketing.agentID,
      callCampaign: marketing.callCampaign,
      callPartnerAccount: marketing.callPartnerAccount,
      campaignName: marketing.campaignName,
      emailConfirmationFlow: marketing.emailConfirmationFlow,
      eventID: marketing.eventID,
      eventName: marketing.eventName,
      gclid: marketing.gclid ?? undefined,
      optinCta: marketing.optinCta,
      optinPoint: startedFulfillmentFlow ? OptinPoint.WEBSITE_REGISTRATION : OptinPoint.WEBSITE_SIMULATION,
      partnerID: marketing.partnerID,
      sfUserAlias: marketing.sfUserAlias,
      shiftID: marketing.shiftID,
      simulationSalesOffice: marketing.simulationSo,
      simulationType: customer.inputs.simulation.chosenSimulationType,
      sourceType: marketing.sourceType
    },
    meterUsage: customer.inputs.simulation.usage,
    mobilePhone:
      (customer.inputs.personalData.mobilePhone &&
        parsePhoneNumberFromString(customer.inputs.personalData.mobilePhone, phoneCountryCode ?? 'BE')?.number?.toString()) ||
      '',
    opportunityId: app.opportunityId,
    price,
    producer: {
      id: producer.id,
      name: producer.name,
      salesforceId: producer.salesforceId,
      slug: producer.slug,
      sourceRegion: producer.energyRegion
    },
    products: {
      electricity: ELProduct[product] || ELProduct.VariableOn,
      gas: NGProduct[product]
    },
    promoCode: customer.inputs.simulation.promoCode,
    referralCode: customer.inputs.simulation.referralCode,
    solarKva: customer.inputs.simulation.solarKva,
    walloniaSolarPanelsSince2024: customer.inputs.meterDetails.walloniaSolarPanelsSince2024,
    zipcode: Number(customer.inputs.personalData.deliveryAddress.postalCode)
  } satisfies CreateProspectData
}

/**
 * Removes spaces and dots in the given string & returns it
 *
 * @param {string} text
 * @returns {string}
 */
export const removeSpacesAndDots = (text: string): string => {
  // \s+ matches all whitespace characters, including spaces, non-break spaces, tabs, new lines and carriage returns
  const noSpaces = text.replace(/\s+/g, '')
  return noSpaces.replace(/\./g, '')
}

/**
 * Removes all non-alphanumeric characters in the given string & returns it
 *
 * @param {string} text
 * @returns {string}
 */
export const removeNonAlphanumericals = (text: string): string => {
  return text.replace(/[^a-zA-Z0-9]/g, '')
}

/**
 * Removes all non-numeric characters in the given string & returns it
 *
 * @param {string} text
 * @returns {string}
 */
export const removeNonNumbers = (text: string): string => {
  return text.replace(/[^0-9]/g, '')
}

/**
 * Shuffles any given array with the Fisher-Yates algorithm
 *
 * @param array
 * @returns any[]
 */
export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]

    array[i] = array[j]
    array[j] = temp
  }

  return array
}

/**
 * Checks for matching strings in given keys
 * @param {string[]} keys
 * @param {string} query
 * @returns {boolean}
 */
export const searchMultipleKeys = (keys: string[], query: string) => keys.some((key) => normalizeText(key).includes(normalizeText(query)))
