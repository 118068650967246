import { MarketingDataKeys, MarketingStore, OptinCta, SimulationType } from 'store/marketing/types'
import { readCookie } from 'utils/cookies'
import { CookieKeys } from 'types/cookies'
import { SourceType } from 'types/marketing-params'

export const initialMarketingState: MarketingStore = {
  [MarketingDataKeys.ADJUSTABLE_PRODUCT]: false,
  [MarketingDataKeys.CONFIRMATION_FROM_PORTAL_REGISTRATION]: false,
  [MarketingDataKeys.EAN_CODE_MANDATORY]: false,
  [MarketingDataKeys.EMAIL_CONFIRMATION_FLOW]: false,
  [MarketingDataKeys.EXECUTE_CREDIT_CHECK]: true,
  [MarketingDataKeys.GCLID]: readCookie(CookieKeys.GCLID),
  [MarketingDataKeys.NO_EAN_FLOW]: false,
  [MarketingDataKeys.NO_OPTIN_ONE]: false,
  [MarketingDataKeys.OPTIN_CTA]: OptinCta.SWITCH_NOW,
  [MarketingDataKeys.PREVIOUS_SUPPLIER]: false,
  [MarketingDataKeys.RESET_BUTTON]: false,
  [MarketingDataKeys.SIGN_CHANNEL]: undefined,
  [MarketingDataKeys.SIMULATION_TYPE]: [SimulationType.PRICING_SIMULATION],
  [MarketingDataKeys.SOURCE_TYPE]: SourceType.PULL,
  [MarketingDataKeys.TRIGGER_EVENTS]: ''
}
