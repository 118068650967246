import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialAppState } from 'store/app/initial'
import { boltApi } from 'store/api/boltApi'
import Router from 'next/router'
import { Language } from 'types/language'
import { filterAndSortProducers } from 'utils/producers'
import { SetAllExperimentsPayload, SetSelectedProducerPayload, SetExperimentPayload, UpdateOpportunityIdPayload } from './types'

export const AppSlice = createSlice({
  initialState: initialAppState,
  name: 'app',
  reducers: {
    setAllExperiments: (state, action: PayloadAction<SetAllExperimentsPayload>) => {
      const { experiments } = action.payload
      return {
        ...state,
        experiments: experiments
      }
    },
    setExperiment: (state, action: PayloadAction<SetExperimentPayload>) => {
      const { assignedVariant, experiment } = action.payload
      return {
        ...state,
        experiments: {
          ...state.experiments,
          [experiment]: assignedVariant
        }
      }
    },
    setSelectedProducer: (state, action: PayloadAction<SetSelectedProducerPayload>) => {
      const { producerSlug } = action.payload
      return {
        ...state,
        selectedProducer: producerSlug
      }
    },
    updateOpportunityId: (state, action: PayloadAction<UpdateOpportunityIdPayload>) => {
      const { opportunityId } = action.payload
      return {
        ...state,
        opportunityId
      }
    },
    updateAccountId: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        accountId: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(boltApi.endpoints.getProducers.matchFulfilled, (state, { payload }) => {
      const language = Router.locale as Language
      const sortedProducers = filterAndSortProducers({
        language,
        producers: Object.values(payload)
      })

      return {
        ...state,
        selectedProducer: sortedProducers[0]?.slug
      }
    })
  }
})

export const { setAllExperiments, setExperiment, setSelectedProducer, updateOpportunityId, updateAccountId } = AppSlice.actions

export default AppSlice.reducer
